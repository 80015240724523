// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cita-js": () => import("./../../../src/pages/cita.js" /* webpackChunkName: "component---src-pages-cita-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-sobre-mi-js": () => import("./../../../src/pages/sobre-mi.js" /* webpackChunkName: "component---src-pages-sobre-mi-js" */),
  "component---src-pages-talleres-js": () => import("./../../../src/pages/talleres.js" /* webpackChunkName: "component---src-pages-talleres-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-taller-js": () => import("./../../../src/templates/taller.js" /* webpackChunkName: "component---src-templates-taller-js" */)
}

